const role = {
    state: {
        sexs: ['男', '女'],
    },
    mutations: {

    },
    actions: {}
}

export default role