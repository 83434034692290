import {deleteRequest, getRequest, postRequest, putRequest} from "@/utils/api.js"

// 文件分页查询
export function filePageData(params = {}) {
    return postRequest('/files/getPageData', params);
}

// 获取全部文件类型
export function getTypes() {
    return getRequest('/files/getTypes');
}

// 删除文件信息
export function deleteFile(id) {
    return deleteRequest('/files/' + id);
}

// 添加文件信息
export function addFile(params = {}) {
    return postRequest('/files', params);
}

// 更新文件信息
export function updateFile(params = {}) {
    return putRequest('/files', params);
}