import {deleteRequest, getRequest, postRequest, putRequest} from "@/utils/api.js"

// 菜单分页查询
export function menuPageData(params = {}) {
    return postRequest('/sys/menu/getPageData', params);
}

// 获取所有的父级菜单
export function getAllParentMenus() {
    return getRequest('/sys/menu/getAllParentMenus');
}

// 获取所有的icon
export function getAllIcons() {
    return getRequest('/sys/menu/getAllIcons');
}

// 删除菜单信息
export function deleteMenu(id) {
    return deleteRequest('/sys/menu/' + id);
}

// 添加菜单信息
export function addMenu(params = {}) {
    return postRequest('/sys/menu', params);
}

// 更新菜单信息
export function updateMenu(params = {}) {
    return putRequest('/sys/menu', params);
}