import {deleteRequest, getRequest, postRequest, putRequest} from "@/utils/api.js"

// 获取所有的国家信息
export function getAllCountries() {
    return getRequest('/xrg/area/countries');
}

// 地区分页查询
export function areaPageData(params = {}) {
    return postRequest('/xrg/area/getPageData', params);
}

// 删除地区信息
export function deleteArea(id) {
    return deleteRequest('/xrg/area/' + id);
}

// 添加地区信息
export function addArea(params = {}) {
    return postRequest('/xrg/area', params);
}

// 更新地区信息
export function updateArea(params = {}) {
    return putRequest('/xrg/area', params);
}