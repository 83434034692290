import {deleteRequest, getRequest, postRequest, putRequest} from "@/utils/api.js"

// 获取所有的角色信息
export function getAllRoles() {
    return getRequest('/xrg/country/roles');
}

// 国家分页查询
export function countryPageData(params = {}) {
    return postRequest('/xrg/country/getPageData', params);
}

// 删除国家信息
export function deleteCountry(id) {
    return deleteRequest('/xrg/country/' + id);
}

// 添加国家信息
export function addCountry(params = {}) {
    return postRequest('/xrg/country', params);
}

// 更新国家信息
export function updateCountry(params = {}) {
    return putRequest('/xrg/country', params);
}